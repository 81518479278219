<template>
  <div>
    <s-crud
      title="Pallets en Piso"
      :filter="filter"
      :config="config"
    >
    </s-crud>
  </div>
</template>

<script>

  import _maturationfloorpallet from "@/services/FrozenProduction/MaturationFloorPalletService.js";
  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  
  
  export default {
    props: {
    }, 
    component: {

    },
    data() {
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial( {color: 0x00ff00, wireframe: true} );
      //material.push(new THREE.MeshBasicMaterial({color: 'yelow'}));
      const renderer = new THREE.WebGLRenderer({antialias: true});
      const cube = new THREE.Mesh(geometry, material);
      

      return {
        filter: {},
        config: {
          model: {
            PifID: "ID",
            PifDateEnd: "datetime"
          },
          service: _maturationfloorpallet,
          headers: [
            {text: "ID", value: "PifID"},
            {text: "N° Pallet", value: "LlpID"},
            {text: "Fecha Salida", value: "PifDateEnd"},
            {text: "Origen", value: "OriginName"},
          ]
        },
        scene,
        camera,
        renderer,
        cube,
        controls: [],
      }
    },

    methods: {
      animate()
      {
        requestAnimationFrame(this.animate);
        this.renderer.render(this.scene, this.camera);
      }
    },

    created () {
      //this.renderer.setZise(window.innerWidth, window.innerHeight);
      
      this.cube.Color = new THREE.Color("red")
      this.scene.add(this.cube);
      this.camera.position.z = 5;
      this.scene.background = new THREE.Color("WHITE");
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      //console.log('aaa', this.renderer.setSize());
    },

    mounted () {
      this.$refs.canvas.appendChild(this.renderer.domElement);
      this.animate();
      //this.controls.update();
    },

  }

</script>