import Service from "../Service";
const resource = "/frzmaturationfloorpallet/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

};